<template lang="pug">
  div
    a(name="demo")
    section.completed
      .container
        .completed__grid
          h2.completed__title
            span.desktop-strong  Посмотрите,
            |  как выглядит
            span.desktop-strong  готовый расчет
            |  внутри сервиса
          .completed__button
            a.button(@click="goDemoDocs") Посмотреть готовый расчет

          .completed__img
            img.lazyload(:data-src="require('images/demo.svg')", alt="")

</template>

<script>
import navigation from 'mixins/navigation'
import 'lazysizes'

export default {
  name: 'Completed',
  mixins: [navigation]
}
</script>
