<template lang="pug">
  section.advantages
    .container
      .advantages__header
        h2.advantages__title Строите сами?
        .advantages__subtitle Принимайте правильные решения,
          br
          | сделав расчеты у нас!

      .advantages__grid
        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/warm-floor.svg
          .advantages__item-title Проверьте быстро
          .advantages__item-desc Хватит ли теплого пола для отопления вашего дома или нужны радиаторы.

        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/efficiency.svg
          .advantages__item-title Спланируйте бюджет заранее
          .advantages__item-desc Готовую спецификацию можно отправить разным поставщикам и сравнить цены.

        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/shield.svg
          .advantages__item-title Подберите оборудование
          .advantages__item-desc В наших справочниках большой выбор материалов и оборудования от проверенных производителей.

        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/study.svg
          .advantages__item-title Получайте знания
          .advantages__item-desc С помощью обучающих материалов внутри сервиса. Консультируйтесь по расчетам через онлайн-чат.

      .advantages__button
        .advantages-arrow
          .advantages-arrow__icon
          .advantages-arrow__title У вас получится!
          .advantages-arrow__desc Даже, если вы делаете это впервые!

        a.button(@click="goSignUp") Регистрация и расчет
</template>

<script>
import navigation from 'mixins/navigation'
export default {
  name: 'Advantages',
  mixins: [navigation]
}
</script>
