<template lang="pug">
  section.gost
    .container
      h2.gost__title Всё по ГОСТам и СНИПам
      .gost__desc
        p
          | Мы полностью соответствуем нормативным документам.
          br
          | Если в процессе расчета вы от них отклонитесь - мы сразу предупредим
          br
          | и подскажем как поправить расчет.
</template>

<script>
export default {
  name: 'Gost'
}
</script>
