<template lang="pug">
  section.banner2
    .container
      .banner2__header
        .banner2__left
          .banner2__left-up
            .banner2__left-up-titles
              h1.banner2__title Расчет отопления
              .banner2__subtitle Калькулятор теплопотерь, водяного теплого пола и радиаторов.
            .banner2__left-up-mobile
              img(:src="require('images/banner-right.svg')" style="width:500px;")
          .banner2__buttons
            .banner2__btn.banner2__btn--order(@click="openBot")
              .banner2__btn-name Заказать расчет у нас
              .banner2__btn-price 45 руб
              .banner2__btn-per за кв. метр
            .banner2__btn.banner2__btn--self(@click="calcMyself")
              .banner2__btn-name Сделать расчет самому
              .banner2__btn-price 0 руб
              .banner2__btn-per за пробный<br>расчет
          a.banner2__estimate-example(@click="getResultsExample") Пример результата расчета
        .banner2__right
          img(:src="require('images/banner-right.svg')")

      .banner2__advantages
        .banner2__advantages-item(@click="goHeatloss")
          .banner2__advantages-item-img
            img(:src="require('images/banner-1-new.svg')" style="width:190px;")
          .banner2__advantages-item-title Теплопотери
          .banner2__advantages-item-desc Считайте упрощенно или подробно. Узнайте необходимую мощность котла

        .banner2__advantages-item(@click="goFloor")
          .banner2__advantages-item-img
            img(:src="require('images/banner-2-new.svg')" style="width:190px;")
          .banner2__advantages-item-title Водяной теплый пол
          .banner2__advantages-item-desc Теплоотдача, количество и длина веток, диаметр и шаг укладки трубы, настройка

        .banner2__advantages-item(@click="goRadiator")
          .banner2__advantages-item-img
            img(:src="require('images/banner-3-new.svg')" style="width:190px;")
          .banner2__advantages-item-title Приборы отопления
          .banner2__advantages-item-desc Секционные и панельные, с вентилем и без. Реальная и паспортная теплоотдача
</template>

<script>
import navigation from 'mixins/navigation'

export default {
  name: 'Banner',
  mixins: [navigation],
  methods: {
    openBot () {
      this.$reachGoal('ORDER_DOCUMENT')
      window.Verbox('showTab', { id: 'qn3gilyhsax9o9xyvg00tu8021ulicim' })
    },
    calcMyself () {
      this.$reachGoal('CALC_MYSELF')
      this.goSignUp()
    },
    getResultsExample () {
      this.$reachGoal('GET_RESULT_EXAMPLE')
      window.open('https://docs.heatingcalculator.online/p/report/1')
    }
  }
}
</script>
