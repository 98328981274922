<template lang="pug">
  section.advantages
    .container
      .advantages__header
        h2.advantages__title Монтажнику
        .advantages__subtitle Делайте расчеты отопления на заказ,
          br
          | зарабатывайте больше!

      .advantages__grid
        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/time.svg
          .advantages__item-title Подготовьтесь ко встрече с заказчиком быстрее и лучше
          .advantages__item-desc 1-2 часа после разговора с заказчиком и осмотра объекта — и у вас все посчитано и оформлено, как у профессиональных проектировщиков.

        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/money.svg
          .advantages__item-title Подтверждайте вашу смету результатами расчета
          .advantages__item-desc Делайте без лишних “запасов” на материалах. Помогайте заказчику не переплачивать за оборудование, а сами получайте заказы легче и зарабатывайте больше.

        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/professional.svg
          .advantages__item-title Пусть заказчик видит,
            br
            | что вы профи
          .advantages__item-desc Будьте уверены в своих расчетах и сметах, даже если объект очень большой и необычный

      .advantages__button
        .advantages-arrow.advantages-arrow_type-2
          .advantages-arrow__icon
          .advantages-arrow__title Научим бесплатно!
          .advantages-arrow__desc Запишись на мастер-класс и выиграй годовой тариф!

        a.button(@click="openVerbox") Записаться на обучение
</template>

<script>
import navigationMixin from 'mixins/navigation'

export default {
  name: 'Advantages2',
  mixins: [navigationMixin],
  methods: {
    openVerbox () {
      window.Verbox('showTab', { id: '8b1beyu3wnua5al998ad1c7asrmi3dp4' })
    }
  }
}
</script>
