<template lang="pug">
  section.result
    .container
      h2.result__title Что вы получите на руки?
      .result__grid
        .result__item
          .result__item-img
            enlargeable-image(:src="heatlossImg" :src_large="heatlossImg" animation_duration="300")
              span
                img.lazyload(:src="require('images/heatloss_small.jpg')", alt="")
          .result__item-content
            .result__item-title Ведомости:
            ul.result__item-desc
              li теплопотерь каждого помещения
              li ограждающих конструкций
              li приборов отопления
              li теплых полов
        .result__item
          .result__item-img
            enlargeable-image(:src="specImg" :src_large="specImg" animation_duration="300")
              span
                img.lazyload(:src="require('images/spec_small.jpg')", alt="")
          .result__item-content
            .result__item-title Спецификация
              br
              | оборудования
              br
              | и материалов
      .result__format
        .result__format-title Отчеты в формате PDF и Excel
        .result__format-item
          .result__format-img
            include ../assets/images/pdf.svg
          .result__format-content
            ul.result__format-desc
              li можно сохранить
              li распечатать
              li отправить заказчику
</template>

<script>
import EnlargeableImage from '@diracleo/vue-enlargeable-image'
import 'lazysizes'

export default {
  name: 'Result',
  data () {
    return {
      heatlossImg: require('../assets/images/heatloss-example.jpg'),
      specImg: require('../assets/images/spec.jpg')
    }
  },
  components: { EnlargeableImage }
}
</script>
