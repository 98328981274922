<template lang="pug">
  section.simple
    .container
      .simple__title Самый простой из точных
        br
        | и самый точный из простых
</template>

<script>
export default {
  name: 'Simple'
}
</script>
